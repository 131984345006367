<template>
  <AppLoader
    v-if="!hasUser && !organization"
    style="margin: 10px"
    center-class="offer"
    :show-modal="!!currentModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  />
  <AppMainContainer
    v-else
    center-class="offer"
  >
    <template
      #center
    />
    <template
      v-if="hasUser && !parseDesc"
      #subheader
    >
      <PageHeader :isAdmin="isOrganizationAdminGroup" />
    </template>
    <template #center>
      <IndexList v-if="hasUser && !parseDesc" />

      <div v-else>
        <h1 style="margin-bottom: 40px">
          {{ $t('index.header') }}
        </h1>
        {{ parseDesc }}
      </div>
    </template>
  </AppMainContainer>
</template>

<script>
import { mapState } from 'vuex'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import userUtil from '@/utils/user-util'
import AppLoader from '@/components/AppLoader'
import IndexList from '@/components/index/IndexList'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  name: 'PageIndex',
  components: {
    PageHeader,
    AppMainContainer, AppLoader,
    IndexList,
  },
  data () {
    const descNoActiveOfficeList = Object.freeze(this.$t('index.errors.no_office'))
    const descNoOfficeList = Object.freeze(this.$t('index.errors.no_active_office'))

    return {
      isScroll: false,
      currentModal: '',
      descNoActiveOfficeList,
      descNoOfficeList,
      userRole: null
    }
  },
  computed: {
    ...mapState('users', {
      'user': 'info'
    }),
    organization () {
      return this.$store.state.organization.organizationUser
    },
    officeExists () {
      return !!this.$store.state.office.userOffice && !!this.$store.state.office.userOffice.id
    },
    hasActiveOffice () {
      return userUtil.hasActiveOffice(this.user)
    },
    hasOffice () {
      return userUtil.hasActiveOffice(this.user)
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.user)
    },
    isOrganizationAdmin () {
      return userUtil.isAdmin(this.user)
    },
    isOrganizationManager () {
      return userUtil.isManager(this.user)
    },
    hasUser () {
      return this.$store.state.users.info && this.$store.state.users.info.id
    },
    parseDesc () {
      let desc = []
      const user = this.user
      if (user) {
        // if (!userUtil.hasAcceptTerms(user)) {
        //   desc = this.$t('index.errors.terms_not_accepted')
        // }
        if (userUtil.isRoot(user)) {
          return null
        } else if (!userUtil.hasActiveOrganization(user)) {
          desc = this.$t('index.errors.no_active_organization')
        } else if (!this.hasOffice) {
          desc = this.$t('index.errors.no_office')
        } else if (!this.hasActiveOffice) {
          desc = this.$t('index.errors.no_active_office')
        } else {
          return null
        }
        return desc.join(' ')
      }

      return 'У вас нет ролей'
    }
  },
  watch: {
    'user' (val) {
      if (val && val.roleList) {
        if (userUtil.isManager(val)) {
          this.userRole = 'manager'
        } else {
          if (userUtil.isRoot(val)) {
            this.userRole = 'administrator'
          }
        }
      }
    }
  },
  created () {
  },
  methods: {
    closeModal () {
      this.currentModal = ''
    }
  }
}
</script>
